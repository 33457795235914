.wizard.wizard-3 .wizard-nav .wizard-steps {
  display: flex;
  align-items: flex-end; }
  .wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-grow: 1;
    margin-right: 1rem; }
    .wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step:last-child {
      margin-right: 0; }
    .wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step .wizard-label {
      flex: 1;
      display: flex;
      flex-direction: column;
      color: #B5B5C3;
      padding: 2rem 0.5rem; }
      .wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title {
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
        flex-wrap: wrap; }
        .wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title span {
          font-size: 2rem;
          margin-right: 0.5rem; }
      .wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-bar {
        height: 4px;
        width: 100%;
        background-color: #EBEDF3;
        position: relative; }
        .wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-bar:after {
          content: " ";
          position: absolute;
          top: 0;
          left: 0;
          height: 4px;
          width: 0;
          background-color: transparent;
          transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease; }
    .wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-label {
      color: #1BC5BD; }
      .wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-label .wizard-bar:after {
        width: 100%;
        background-color: #1BC5BD; }

@media (max-width: 991.98px) {
  .wizard.wizard-3 .wizard-nav .wizard-steps {
    flex-direction: column;
    align-items: flex-start; }
    .wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step {
      flex: 0 0 100%;
      position: relative;
      width: 100%; }
      .wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step .wizard-label {
        justify-content: flex-start;
        flex: 0 0 100%;
        padding: 1rem 0; } }
